@import "src/themes/mojito/styles/index.scss";
.categories-carousel {
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none;
  padding: 6px 4px;

  &__category-container {
    box-shadow: 2px 0px 6px 0px rgba(166, 166, 166, 0.25);
    padding: 16px !important;
    min-width: 300px !important;
    width: auto !important;
    flex: 0 0 auto !important;
    justify-content: space-between;
    gap: 16px !important;
    white-space: nowrap;

    &__title {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      p:first-of-type {
        color: getvar($colors, "neutral", "500");
        font-size: 14px;
        line-height: 14px;
      }

      p:nth-of-type(2) {
        color: #fe4256;
        font-size: 10px;
        margin-bottom: 0;
        line-height: 14px;
      }
    }

    &__icons {
      color: getvar($colors, "warning", "500");
      position: relative;
      top: 2px;
      margin-right: 4px;
    }

    &__rate {
      color: getvar($colors, "neutral", "900") !important;
      font-weight: bold !important;
      font-size: 16px !important;
    }
  }
}
