@import "src/themes/mojito/styles/index.scss";
.payments__table {
  width: 100%;
  margin-bottom: 1rem;

  .payment_container_mobile {
    margin-bottom: 18px;

    display: block;

    @include sm {
      display: none;
    }
    .payment_column {
      background-color: getvar($colors, "primary", "50");
      padding: 6px 16px;
    }
    .payment_column:last-of-type {
      padding: 16px;
      background-color: transparent;
      display: flex;
      justify-content: center;

      .button--square {
        text-align: center;
        margin: 0px 1rem;
        width: 100%;
        max-width: 250px;
        @include sm {
          margin: 0;
          min-width: 140px;
        }
      }
    }

    .payment_row {
      display: flex;
      justify-content: space-between;
    }
    .payment_row:first-of-type {
      align-items: flex-start;
    }
    .payment_header.mobile {
      flex-basis: 100%;

      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 12px;
      }
    }
    .payment_item.mobile {
      flex-basis: 100%;
      font-weight: 400;

      p {
        font-size: 14px;
        line-height: 18px;
        color: getvar($colors, "neutral", "500");
        margin-bottom: 12px;
      }

      &.card {
        text-align: right;
      }
    }
  }

  .payment_container {
    display: none;

    @include sm {
      display: block;
    }
    .payments__table__header {
      display: flex;
      background-color: getvar($colors, "primary", "50");
      padding: 16px;

      @include sm {
        padding: 16px 6px;
      }

      .payment_header {
        flex-basis: 100%;

        @include sm {
          p {
            text-align: center !important;
          }
        }

        p {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 0;
        }

        &.amount {
          @include sm {
            flex-basis: 55%;
          }
        }
        &.type {
          @include sm {
            flex-basis: 65%;
          }
        }
        &.amount,
        &.type {
          p {
            @include sm {
              text-align: center;
            }
            @include lg {
              text-align: left;
            }
          }
        }

        &.date {
          @include sm {
            flex-basis: 80%;
          }

          @include lg {
            flex-basis: 40%;
          }
        }

        &.invoice {
          @include sm {
            text-align: center;
            flex-basis: 80%;
          }
        }
        &.invoice-button {
          @include sm {
            min-width: 135px;
          }
        }
      }

      .payment_header.date {
        @include sm {
          p {
            text-align: left !important;
          }
        }
      }
    }
    .payments__table__row {
      display: flex;
      background-color: transparent;
      padding: 16px;
      border-bottom: 1px solid getvar($colors, "neutral", "300");

      @include sm {
        padding: 16px 6px;
        align-items: center;
      }
      .payment_item {
        flex-basis: 100%;
        font-weight: 400;

        @include sm {
          p {
            text-align: center !important;
          }
        }

        p {
          font-size: 14px;
          line-height: 18px;
          color: getvar($colors, "neutral", "500");
          margin-bottom: 0;
        }

        &.date {
          @include sm {
            flex-basis: 80%;
          }
          @include lg {
            flex-basis: 40%;
          }
        }

        &.amount {
          @include sm {
            flex-basis: 55%;
          }
          /*   p {
            @include sm {
              text-align: center;
            }
            @include lg {
              text-align: left;
            }
          } */
        }
        &.type {
          @include sm {
            display: flex;
            justify-content: center;

            flex-basis: 65%;
          }
        }
        &.invoice {
          @include sm {
            text-align: center;
            flex-basis: 80%;
          }
        }

        &.invoice-button {
          .button--square {
            text-align: center;
            margin: 0px 1rem;
            width: 100%;

            @include sm {
              margin: 0;
              min-width: 140px;
              max-width: 150px;
            }
          }
        }
      }

      .payment_item.date {
        @include sm {
          p {
            text-align: left !important;
          }
        }
      }
    }
    .payments__table__row:last-of-type {
      border-bottom: none;
    }

    .payments__table__row.mobile {
      display: flex;
      background-color: transparent;
      padding: 16px;
      border-bottom: 1px solid getvar($colors, "neutral", "300");

      .payment_item {
        flex-basis: 100%;
        font-weight: 400;

        p {
          font-size: 14px;
          line-height: 18px;
          color: getvar($colors, "neutral", "500");
        }
        &.amount {
          p {
            @include sm {
              text-align: center;
            }
            @include lg {
              text-align: left;
            }
          }
        }
        &.type {
          @include sm {
            display: flex;
            justify-content: center;
          }
          @include lg {
            display: block;
          }
        }
      }
    }
    .payments__table__row.mobile:last-of-type {
      border-bottom: none;
    }
  }
}
