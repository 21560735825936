@import "src/themes/mojito/styles/index.scss";
.review-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid getvar($colors, "neutral", "200");

  &__header {
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: space-between;
    flex-direction: column;

    @include md {
      flex-direction: row;
    }

    .review-userInfo {
      display: flex;
      flex-direction: column;
      gap: 4px;
      &_title {
        margin: 0;
        font-weight: bold;
        font-size: 18px;
        color: getvar($colors, "primary", "500");
      }

      &_subtitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: getvar($colors, "neutral", "500");
        gap: 4px;

        &_items {
          margin: 0;
        }
      }
    }

    .review-averageRating {
      display: flex;
      align-self: center;
      align-items: center;
      gap: 16px;
      padding: 16px;
      border-radius: 8px;
      background-color: getvar($colors, "primary", "50");
      width: -webkit-fill-available;
      justify-content: space-between;

      svg:nth-child(n + 2) {
        display: none;
      }

      @include md {
        width: initial;
        flex-direction: row;

        svg:nth-child(n + 2) {
          display: block;
        }
      }

      &_title {
        font-weight: bold;
        color: getvar($colors, "primary", "500");
        margin-bottom: 0;
      }

      &_rate {
        display: flex;
        flex-direction: row;
        gap: 6px;

        &_icons {
          color: getvar($colors, "warning", "500");
          display: flex;
          align-self: center;
          gap: 1px;
          display: none;

          @include md {
            display: contents;
          }
        }

        &_icons--mobile {
          color: getvar($colors, "warning", "500");
          display: flex;
          align-self: center;
          gap: 1px;

          @include md {
            display: none;
          }
        }

        &_text {
          font-weight: bold;
          margin-bottom: 0;
        }
      }
    }
  }

  .review-category {
    display: flex;
    padding: 16px;
    border-radius: 8px;
    align-items: flex-start;
    gap: 16px;
    background-color: getvar($colors, "primary", "50");
    width: -webkit-fill-available;

    .review-category_header {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      @include md {
        flex-direction: column;
      }

      &_content {
        @include md {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .marginRight {
        @include md {
          margin-right: 116px;
        }
      }

      .review-category_content_rating--mobile {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        align-self: baseline;

        @include md {
          display: none;
        }

        .review-category_content_icons--mobile {
          color: getvar($colors, "warning", "500");
          display: flex;
          align-self: center;
        }

        .review-category_content_rate--mobile {
          display: block;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
    }

    &_content {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 100%;
      justify-content: space-between;

      .text-truncate {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &_text {
        color: black;
        text-align: justify;
        margin-bottom: 0;
      }
      &_rating {
        display: none;

        @include md {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
      &_icons {
        display: none;

        @include md {
          color: getvar($colors, "warning", "500");
          display: flex;
          align-self: center;
          gap: 1px;
        }
      }
      &_rate {
        display: none;

        @include md {
          display: block;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
    }

    &_title {
      color: black;
      font-weight: bold;
      margin-bottom: 0;
    }

    &_subtitle {
      color: #fe4256;
      font-size: 10px;
      margin-bottom: 0;
      line-height: 20px;
    }
  }
  .button--view-more-button {
    height: auto;
  }
}
